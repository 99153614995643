import { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner, faBan } from '@fortawesome/free-solid-svg-icons'
import { getEventFilterAPI, getFavoriteEventsAPI } from '@api/index'
import { LocalVar } from '@utils/index'
import { EventCard } from '@components/atoms/events/index'
import { Pagination } from '@components/atoms/index'

const FavoriteEventCards = ({ upcoming = true, isFavouriteEvents = true }) => {
    const [data, setData] = useState({ data: [] })
    const [loading, setLoading] = useState(false)
    const [page, setPage] = useState(0)

    useEffect(() => {
        setLoading(true)
        const token = JSON.parse(localStorage.getItem(LocalVar.AUTH_DATA))

        const getDataFunction = async (token, page, limit, upcoming) => {
            const getData = await getFavoriteEventsAPI(token, page, limit, upcoming)
            if (getData.error === null) {
                setData(getData.data.data)
                setLoading(false)
            } else {
                setLoading(false)
            }
        }
        const getRecommendedData = async (
            by,
            search,
            limit,
            page,
            preparedUpcomming,
            featured,
            category,
            ces,
            startPrice,
            endPrice,
            country,
            stateProvince,
            city,
            nationality,
            startDate,
            endDate,
            sortBy,
        ) => {
            const getData = await getEventFilterAPI(
                by,
                search,
                limit,
                page,
                preparedUpcomming,
                featured,
                category,
                ces,
                startPrice,
                endPrice,
                country,
                stateProvince,
                city,
                nationality,
                startDate,
                endDate,
                sortBy,
            )
            if (getData.error === null) {
                setData(getData.data.data)
                setLoading(false)
            } else {
                setLoading(false)
            }
        }

        let params = JSON.parse(localStorage.getItem('recommendedEventsParams'))
        isFavouriteEvents
            ? getDataFunction(token, page, 9, upcoming)
            : getRecommendedData(
                  params?.by || 'all',
                  params?.search || '',
                  params?.limit || 9,
                  page,
                  params?.upcoming || upcoming,
                  params?.featured || null,
                  params?.category || 'all',
                  params?.ceSpeciality || 'all',
                  params?.startPrice || null,
                  params?.endPrice || null,
                  params?.country || null,
                  params?.stateProvince || null,
                  params?.city || null,
                  params?.nationality || '',
                  params?.startDate || '',
                  params?.endDate || '',
                  params?.sortBy || 'created_at',
              )
    }, [page, upcoming, isFavouriteEvents])

    return (
        <div className="pt-8">
            {loading ? (
                <div className="flex justify-center items-center min-h-[200px]">
                    <FontAwesomeIcon
                        icon={faSpinner}
                        className="motion-safe:animate-spin text-4xl text-primary-green-2"
                    />
                </div>
            ) : (
                <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
                    {data?.data?.length !== 0
                        ? data?.data?.map((v, i) => {
                              return (
                                  <div key={i} className="col-span-4">
                                      <EventCard data={v} />
                                  </div>
                              )
                          })
                        : null}
                </div>
            )}
            {data?.data?.length === 0 && (
                <div className="flex justify-center items-center">
                    <h3 className="text-gray-400 text-center font-medium">
                        <FontAwesomeIcon icon={faBan} /> No events found
                    </h3>
                </div>
            )}
            {data.total_data_count > 9 ? (
                <Pagination
                    prevPageCount={data.prev_page_count}
                    currentPageProp={data.current_page}
                    nextPageCount={data.next_page_count}
                    setPage={setPage}
                />
            ) : null}
        </div>
    )
}

export default FavoriteEventCards
