import { SideBar, FavoriteEventCards } from '@components/molecules/index'
import { useState } from 'react'

const FavoriteEventsTemplate = () => {
    const [upcoming, setUpcoming] = useState(true)
    const [isFavouriteEvents, setIsFavouriteEvents] = useState(true)

    return (
        <div className="h-screen">
            <SideBar>
                <div className="pt-6 px-8">
                    <h2 className="block md:flex items-center select-none">
                        Personalized events
                        <span
                            onClick={() => setIsFavouriteEvents(!isFavouriteEvents)}
                            className="md:ml-4 text-xs md:text-sm font-medium rounded-full bg-slate-300 py-2 cursor-pointer inline-block">
                            <span
                                className={
                                    isFavouriteEvents
                                        ? 'px-4 py-2 rounded-full text-primary-golden-6 bg-primary-green-1 uppercase'
                                        : 'px-4 py-2 rounded-full uppercase'
                                }>
                                Favourite
                            </span>
                            <span
                                className={
                                    isFavouriteEvents
                                        ? 'px-4 py-2 rounded-full uppercase'
                                        : 'px-4 py-2 rounded-full text-primary-golden-6 bg-primary-green-1 uppercase'
                                }>
                                Recommended
                            </span>
                        </span>
                    </h2>
                    <h2 className="block md:flex items-center select-none py-2">
                        <span
                            onClick={() => setUpcoming(!upcoming)}
                            className="text-xs md:text-sm font-medium rounded-full bg-slate-300 py-2 cursor-pointer inline-block">
                            <span
                                className={
                                    upcoming
                                        ? 'px-4 py-2 rounded-full text-primary-golden-6 bg-primary-green-1 uppercase'
                                        : 'px-4 py-2 rounded-full uppercase'
                                }>
                                Upcoming <span className="hidden md:inline-block">events</span>
                            </span>
                            <span
                                className={
                                    upcoming
                                        ? 'px-4 py-2 rounded-full uppercase'
                                        : 'px-4 py-2 rounded-full text-primary-golden-6 bg-primary-green-1 uppercase'
                                }>
                                Previous events
                            </span>
                        </span>
                    </h2>
                    <FavoriteEventCards upcoming={upcoming} isFavouriteEvents={isFavouriteEvents} />
                </div>
            </SideBar>
        </div>
    )
}

export default FavoriteEventsTemplate
