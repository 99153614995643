import { MainNav } from '@components/molecules'
import { Header } from '@components/organisms'
import React, { useEffect, useState } from 'react'
import TopRatedImg from '../../assets/img/top-rated.jpg'
import { Hidden } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBan, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { EventCard } from '@components/atoms/events'
import { Pagination } from '@components/atoms'
import SponsorsBanner from '../../assets/img/sponsors-banner.png'
import MahshaCardBanner from '../../assets/img/MahsaCardBanner.png'
import SepidehCardBanner from '../../assets/img/sepidehCardBanner.png'
import { getTopRatedEventsAPI } from '@api/eventsApi'
import { LocalVar } from '@utils/variables'
import { useLocation } from 'react-router-dom'
import { getTopRatedOrganizersAPI } from '@api/organizerApi'
import { getTopRatedSpeakersAPI } from '@api/speakerApi'

export default function TopRatedPage() {
    const { pathname } = useLocation()
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState({ data: [] })
    const [page, setPage] = useState(0)

    useEffect(() => {
        ;(async () => {
            const token = JSON.parse(localStorage.getItem(LocalVar.AUTH_DATA))
            const eventData =
                pathname === '/top-rated/events'
                    ? await getTopRatedEventsAPI(token)
                    : pathname === '/top-rated/organizers'
                      ? await getTopRatedOrganizersAPI(token)
                      : pathname === '/top-rated/speakers'
                        ? await getTopRatedSpeakersAPI(token)
                        : { data: [] }
            console.log('eventData', eventData)
            if (eventData.error === null) {
                setData({ ...eventData.data.data, data: [...eventData.data.data.data] })
            } else {
                setData([])
            }
        })()
    }, [pathname])

    return (
        <div>
            <Header title="Top Rated" />
            <MainNav />
            <div className="w-full h-96">
                <img src={TopRatedImg} alt="Top Rated" className="w-full h-full object-cover" />
            </div>
            <div className="bg-light-main-green">
                <div className="flex p-4">
                    <Hidden smDown>
                        {/* Main Content */}
                        <div className="w-6/12 p-4 bg-light-main-green">
                            <div className="">
                                {loading ? (
                                    <div className="flex justify-center items-center min-h-[200px]">
                                        <FontAwesomeIcon
                                            icon={faSpinner}
                                            className="motion-safe:animate-spin text-4xl text-primary-green-2"
                                        />
                                    </div>
                                ) : (
                                    <>
                                        <p className="font-bold mb-3 golden-txt text-xl">
                                            Total Events : <span>{data?.total_data_count || 0}</span>
                                        </p>
                                        <div className="grid grid-cols-1 md:grid-cols-12 gap-4 overflow-y-scroll max-h-[1000px] md:pr-2 pb-4">
                                            {pathname === '/top-rated/events' && data?.data?.length !== 0
                                                ? data?.data?.map((v, i) => {
                                                      return (
                                                          <div key={i} className="col-span-12">
                                                              <EventCard data={v} />
                                                          </div>
                                                      )
                                                  })
                                                : null}
                                            {pathname === '/top-rated/organizers' && data?.data?.length !== 0
                                                ? data?.data?.map((v, i) => {
                                                      return (
                                                          <div key={i} className="col-span-12">
                                                              <EventCard data={v} />
                                                          </div>
                                                      )
                                                  })
                                                : null}
                                            {pathname === '/top-rated/speakers' && data?.data?.length !== 0
                                                ? data?.data?.map((v, i) => {
                                                      return (
                                                          <div key={i} className="col-span-12">
                                                              <EventCard data={v} />
                                                          </div>
                                                      )
                                                  })
                                                : null}
                                        </div>
                                    </>
                                )}
                                {data?.data?.length === 0 && !loading && (
                                    <div className="flex justify-center items-center bg-white rounded-md border-2 border-slate-200 py-8">
                                        <h3 className="text-gray-400 text-center font-medium">
                                            <FontAwesomeIcon icon={faBan} /> No events found
                                        </h3>
                                    </div>
                                )}
                                {data.total_data_count > 100 ? (
                                    <Pagination
                                        prevPageCount={data.prev_page_count}
                                        currentPageProp={data.current_page}
                                        nextPageCount={data.next_page_count}
                                        setPage={setPage}
                                    />
                                ) : null}
                            </div>
                        </div>

                        {/* Right Sidebar */}
                        <div className="w-6/12 p-4">
                            <div className="flex flex-col gap-6 border rounded shadow-sm mb-4 bg-gradient-to-r from-indigo-50 to-indigo-200">
                                <div>
                                    <img src={SponsorsBanner} height={300} alt="" />
                                </div>
                            </div>

                            <div className="border rounded shadow-sm mb-4 bg-[#1E201E]">
                                <div>
                                    <img src={MahshaCardBanner} height={300} alt="" />
                                </div>
                            </div>

                            <div className="border rounded shadow-sm bg-[#1E201E]">
                                <div>
                                    <img src={SepidehCardBanner} height={300} alt="" />
                                </div>
                            </div>
                        </div>
                    </Hidden>
                    <Hidden smUp>
                        <div className="w-full p-4 bg-light-main-green">
                            <div className="h-full w-full">
                                {/* Sponsor Content */}

                                <div className="flex flex-col gap-6 border rounded shadow-sm mb-4 bg-gradient-to-r from-indigo-50 to-indigo-200">
                                    <div>
                                        <img src={SponsorsBanner} height={300} alt="" />
                                    </div>
                                    <div className="border rounded shadow-sm mb-4 bg-[#1E201E]">
                                        <div>
                                            <img src={MahshaCardBanner} height={300} alt="" />
                                        </div>
                                    </div>

                                    <div className="border rounded shadow-sm bg-[#1E201E]">
                                        <div>
                                            <img src={SepidehCardBanner} height={300} alt="" />
                                        </div>
                                    </div>
                                </div>

                                {/* Events */}
                                <div className="w-full mb-4">
                                    <div className="pt-4">
                                        {loading ? (
                                            <div className="flex justify-center items-center min-h-[200px]">
                                                <FontAwesomeIcon
                                                    icon={faSpinner}
                                                    className="motion-safe:animate-spin text-4xl text-primary-green-2"
                                                />
                                            </div>
                                        ) : (
                                            <>
                                                <p className="font-bold mb-3 golden-txt text-xl">
                                                    Total Events : <span>{data?.total_data_count || 0}</span>
                                                </p>
                                                <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
                                                    {data?.data?.length !== 0
                                                        ? data?.data?.map((v, i) => {
                                                              return (
                                                                  <div key={i} className="col-span-12">
                                                                      <EventCard data={v} />
                                                                  </div>
                                                              )
                                                          })
                                                        : null}
                                                </div>
                                            </>
                                        )}
                                        {data?.data?.length === 0 && !loading && (
                                            <div className="flex justify-center items-center bg-white rounded-md border-2 border-slate-200 py-8">
                                                <h3 className="text-gray-400 text-center font-medium">
                                                    <FontAwesomeIcon icon={faBan} /> No events found
                                                </h3>
                                            </div>
                                        )}
                                        {data.total_data_count > 20 ? (
                                            <Pagination
                                                prevPageCount={data.prev_page_count}
                                                currentPageProp={data.current_page}
                                                nextPageCount={data.next_page_count}
                                                setPage={setPage}
                                            />
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Hidden>
                </div>
            </div>
        </div>
    )
}
