import { getEventFilterAPI } from '@api/eventsApi'
import { Button, HeroSearchBar, Input, Pagination } from '@components/atoms'
import { EventCard } from '@components/atoms/events'
import { EventCards, EventSearch, MainNav } from '@components/molecules'
import { faBan, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    EventCategoryArray,
    attendByOptions,
    dentalCategoryOptions,
    eventsByOptions,
    findByOptions,
    priceFilterOptions,
    ratingFilterOptions,
    sortByOptions,
} from '@utils/variables'
import React, { useState } from 'react'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import GroupsIcon from '@mui/icons-material/Groups'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import { UpComingEventBtn } from '@components/atoms/forSections'
import { Hidden } from '@mui/material'
import FilterListIcon from '@mui/icons-material/FilterList'
import Box from '@mui/material/Box'
import Slider from '@mui/material/Slider'
import { CitySelect, CountrySelect, StateSelect } from 'react-country-state-city'
import dayjs from 'dayjs'
import isoWeek from 'dayjs/plugin/isoWeek'
import SponsorsBanner from '../../assets/img/sponsors-banner.png'
import MahshaCardBanner from '../../assets/img/MahsaCardBanner.png'
import SepidehCardBanner from '../../assets/img/sepidehCardBanner.png'
import MahsaImg from '../../assets/img/Mahsa2.png'
import SepidiImg from '../../assets/img/Sepideh2.png'

import 'react-country-state-city/dist/react-country-state-city.css'

export default function FindEventsSearchedTemplate({
    searchBy,
    setSearchBy,
    page,
    setPage,
    data,
    search,
    upcoming,
    setUpcoming,
    category,
    setCategory,
    ceSpecialities,
    setCeSpecialities,
    priceValue,
    setPriceValue,
    country,
    setCountry,
    state,
    setState,
    city,
    setCity,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    eventsBy,
    setEventsBy,
    sortBy,
    setSortBy,
}) {
    const [loading, setLoading] = useState(false)
    const [expanded, setExpanded] = React.useState('panel0')
    const [mobFilterExpanded, setMobFilterExpanded] = useState('')
    const [ceSpeciality, setCeSpeciality] = useState('all')
    const [speakerRating, setSpeakerRating] = useState('all')
    const [priceRange, setPriceRange] = useState('all')
    const [stateid, setstateid] = useState('')
    const [countryEmoji, setCountryEmoji] = useState('')
    const [countryid, setCountryid] = useState('')
    const [selectedFilters, setSelectedFilters] = useState([])
    const [catLocal, setCatLocal] = useState('')

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false)
    }

    const handleMobParentAccordionChange = (panel) => (event, isExpanded) => {
        setMobFilterExpanded(isExpanded ? panel : false)
    }

    const handleCheckboxValueChange = (cType, val) => {
        if (cType === 'category') {
            setCategory(val)
            // setCeSpeciality('all')
        } else if (cType === 'ce_speciality') {
            setCeSpeciality(val)
            // setCategory('all')
        } else if (cType === 'find_by') {
            setSearchBy(val)
        } else if (cType === 'speakerRating') {
            setSpeakerRating(val)
        }
    }

    const handleCeSpecilities = (param) => {
        setCeSpecialities((prevCeSpecilities) => {
            // Split the string into an array
            const ceSpecialitiesArray = prevCeSpecilities ? prevCeSpecilities.split(',') : []

            let newCeSpecialitiesArray
            if (ceSpecialitiesArray.includes(param)) {
                newCeSpecialitiesArray = ceSpecialitiesArray.filter((n) => n !== param)
            } else {
                newCeSpecialitiesArray = [...ceSpecialitiesArray, param]
            }

            // Join the array elements back into a string
            return newCeSpecialitiesArray.join(',')
        })
    }

    function valuetext(value) {
        return `$${value}`
    }

    const priceHandleChange = (event, newValue) => {
        setPriceValue(newValue)
    }

    const handleEventsByChange = (evntBy) => {
        if (evntBy === 'thisWeek') {
            const startOfWeek = dayjs().startOf('week').add(1, 'days') // Monday
            const endOfWeek = dayjs().startOf('week').add(7, 'days') // Sunday
            setStartDate(startOfWeek.format('YYYY-MM-DD'))
            setEndDate(endOfWeek.format('YYYY-MM-DD'))
        } else if (evntBy === 'thisWeekend') {
            const saturday = dayjs().startOf('week').add(6, 'day') // Saturday
            const sunday = dayjs().startOf('week').add(7, 'day') // Sunday
            setStartDate(saturday.format('YYYY-MM-DD'))
            setEndDate(sunday.format('YYYY-MM-DD'))
        } else if (evntBy === 'thisMonth') {
            const startOfMonth = dayjs().startOf('month') // First day of the month
            const endOfMonth = dayjs().endOf('month') // Last day of the month
            setStartDate(startOfMonth.format('YYYY-MM-DD'))
            setEndDate(endOfMonth.format('YYYY-MM-DD'))
        } else if (evntBy === 'upcoming') {
            setStartDate('')
            setEndDate('')
        } else if (eventsBy === 'previous') {
            setStartDate('')
            setEndDate('')
        }
        setEventsBy(evntBy)
    }

    const handleFilterResetReset = () => {
        setSearchBy('all')
        setPage(0)
        setCategory('all')
        setCeSpecialities('all')
        setPriceValue([null, null])
        setCountry('')
        setCountryid('')
        setCountryEmoji('')
        setstateid('')
        setState('')
        setCity('')
        setStartDate('')
        setEndDate('')
        setEventsBy('all')
        window.location.reload()
        setSortBy('')
    }

    return (
        <div className="bg-light-main-green">
            <div className="flex p-4">
                <Hidden smDown>
                    {/* Sidebar */}
                    <div className="w-3/12 p-4 bg-light-main-green">
                        <div className="bg-white p-4 h-full">
                            {/* <div onSubmit={handleFilterSubmit}> */}
                            <Button onClick={() => handleFilterResetReset()}>Reset Filters</Button>
                            <div>
                                <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel3bh-content"
                                        id="panel3bh-header">
                                        <Typography sx={{ width: '75%', flexShrink: 0 }}>Sort By</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div>
                                            {sortByOptions.map((cat, i) => (
                                                <div className="mt-2 flex gap-0" key={i}>
                                                    <input
                                                        type="checkbox"
                                                        checked={sortBy === cat?.value}
                                                        onChange={() => setSortBy(cat?.value)}
                                                        className="mr-2"
                                                        id={'by' + i.toString()}
                                                    />
                                                    <label for={'by' + i.toString()} className="cursor-pointer">
                                                        {cat?.text || ''}
                                                    </label>
                                                </div>
                                            ))}
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel4bh-content"
                                        id="panel4bh-header">
                                        <Typography sx={{ width: '75%', flexShrink: 0 }}>Attend By</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div>
                                            {attendByOptions.map((cat, i) => (
                                                <div className="mt-2 flex gap-0" key={i}>
                                                    <input
                                                        type="checkbox"
                                                        checked={searchBy === cat?.value}
                                                        onChange={() => setSearchBy(cat?.value)}
                                                        className="mr-2"
                                                        id={'by' + i.toString()}
                                                    />
                                                    <label for={'by' + i.toString()} className="cursor-pointer">
                                                        {cat?.text || ''}
                                                    </label>
                                                </div>
                                            ))}
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion expanded={expanded === 'panel0'} onChange={handleChange('panel0')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel0bh-content"
                                        id="panel0bh-header">
                                        <Typography sx={{ width: '75%', flexShrink: 0 }}>Date</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div>
                                            {/* <div className="mt-2 flex gap-0">
                                                <input
                                                    type="checkbox"
                                                    checked={upcoming}
                                                    onChange={() => setUpcoming(true)}
                                                    className="mr-2"
                                                    id="up"
                                                />
                                                <label for={'up'} className="cursor-pointer">
                                                    Upcomming
                                                </label>
                                            </div>
                                            <div className="mt-2 flex gap-0">
                                                <input
                                                    type="checkbox"
                                                    checked={!upcoming}
                                                    onChange={() => setUpcoming(false)}
                                                    className="mr-2"
                                                    id="prev"
                                                />
                                                <label for={'prev'} className="cursor-pointer">
                                                    Previous
                                                </label>
                                            </div> */}
                                            {eventsByOptions.map((cat, i) => (
                                                <>
                                                    <div className="mt-2 flex gap-0" key={i}>
                                                        {/* <input
                                                        type="checkbox"
                                                        checked={ceSpeciality === cat?.value}
                                                        onChange={() => setCeSpeciality(cat?.value)}
                                                        className="mr-2"
                                                    /> */}
                                                        <input
                                                            type="checkbox"
                                                            checked={eventsBy === cat?.value}
                                                            onChange={() => handleEventsByChange(cat?.value)}
                                                            className="mr-2"
                                                            id={'spec' + i.toString()}
                                                        />
                                                        <label for={'spec' + i.toString()} className="cursor-pointer">
                                                            {cat?.text || ''}
                                                        </label>
                                                    </div>
                                                </>
                                            ))}
                                            {eventsBy === 'custom' && (
                                                <>
                                                    <div className="col-span-12 md:col-span-6">
                                                        <Input
                                                            type="date"
                                                            name="event-start-date"
                                                            label="Start date"
                                                            labelClass="text-md font-semibold"
                                                            inputClass="block w-full border-2 border-gray-300 p-2 mt-2 mb-6 focus:outline-0 rounded-md "
                                                            placeHolder=""
                                                            value={startDate}
                                                            setValue={setStartDate}
                                                            required={true}
                                                        />
                                                    </div>
                                                    <div className="col-span-12 md:col-span-6">
                                                        <Input
                                                            type="date"
                                                            name="event-end-date"
                                                            label="End date"
                                                            labelClass="text-md font-semibold"
                                                            inputClass="block w-full border-2 border-gray-300 p-2 mt-2 mb-6 focus:outline-0 rounded-md "
                                                            placeHolder=""
                                                            value={endDate}
                                                            setValue={setEndDate}
                                                            required={true}
                                                        />
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header">
                                        <Typography sx={{ width: '75%', flexShrink: 0 }}>Events Type</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div>
                                            {[{ key: 'ALL', value: { value: 'all' } }]
                                                .concat(EventCategoryArray)
                                                .map((cat, i) => (
                                                    <div className="mt-2 flex gap-0" key={i}>
                                                        <input
                                                            type="checkbox"
                                                            checked={category === cat?.value?.value}
                                                            onChange={() => setCategory(cat?.value?.value)}
                                                            className="mr-2"
                                                            id={'cat' + i.toString()}
                                                        />
                                                        <label for={'cat' + i.toString()} className="cursor-pointer">
                                                            {cat?.key || ''}
                                                        </label>
                                                    </div>
                                                ))}
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel2bh-content"
                                        id="panel2bh-header">
                                        <Typography sx={{ width: '75%', flexShrink: 0 }}>CE Speciality</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div>
                                            {[{ text: 'All', value: 'all' }]
                                                .concat(dentalCategoryOptions)
                                                .map((cat, i) => (
                                                    <div className="mt-2 flex gap-0" key={i}>
                                                        {/* <input
                                                        type="checkbox"
                                                        checked={ceSpeciality === cat?.value}
                                                        onChange={() => setCeSpeciality(cat?.value)}
                                                        className="mr-2"
                                                    /> */}
                                                        <input
                                                            type="checkbox"
                                                            checked={
                                                                ceSpecialities.includes(cat?.value) ||
                                                                ceSpecialities.includes('all')
                                                            }
                                                            onChange={() => handleCeSpecilities(cat?.value)}
                                                            className="mr-2"
                                                            id={'spec' + i.toString()}
                                                        />
                                                        <label for={'spec' + i.toString()} className="cursor-pointer">
                                                            {cat?.text || ''}
                                                        </label>
                                                    </div>
                                                ))}
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel5bh-content"
                                        id="panel5bh-header">
                                        <Typography sx={{ width: '75%', flexShrink: 0 }}>Price</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div>
                                            <Box sx={{ width: '100%' }}>
                                                <Slider
                                                    getAriaLabel={() => 'Price range'}
                                                    value={priceValue}
                                                    onChange={priceHandleChange}
                                                    valueLabelDisplay="auto"
                                                    getAriaValueText={valuetext}
                                                    min={0}
                                                    max={1200}
                                                />
                                            </Box>
                                        </div>
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel6bh-content"
                                        id="panel6bh-header">
                                        <Typography sx={{ width: '75%', flexShrink: 0 }}>Country/State/City</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div>
                                            <h6>Country</h6>
                                            <CountrySelect
                                                // defaultValue={{
                                                //     emoji: countryEmoji,
                                                //     id: countryid,
                                                //     name: country,
                                                // }}
                                                onChange={(e) => {
                                                    setCountryEmoji(e.emoji)
                                                    setCountryid(e.id)
                                                    setCountry(e?.name || '')
                                                }}
                                                placeHolder="Select Country"
                                            />
                                            <h6>State</h6>
                                            <StateSelect
                                                countryid={countryid}
                                                onChange={(e) => {
                                                    setstateid(e.id)
                                                    setState(e?.name || '')
                                                }}
                                                placeHolder="Select State"
                                            />
                                            <h6>City</h6>
                                            <CitySelect
                                                countryid={countryid}
                                                stateid={stateid}
                                                onChange={(e) => {
                                                    setCity(e?.name || '')
                                                }}
                                                placeHolder="Select City"
                                            />
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        </div>
                    </div>

                    {/* Main Content */}
                    <div className="flex-1 p-4">
                        <div className="">
                            {/* {!search && (
                                <EventCards
                                    title={upcoming ? 'Upcoming events' : 'Previous events'}
                                    by="event_name"
                                    keyword=""
                                    upcoming={upcoming}
                                    featured={null}
                                    limitPerPage={20}
                                    isMiddleSection={true}
                                />
                            )} */}
                            {loading ? (
                                <div className="flex justify-center items-center min-h-[200px]">
                                    <FontAwesomeIcon
                                        icon={faSpinner}
                                        className="motion-safe:animate-spin text-4xl text-primary-green-2"
                                    />
                                </div>
                            ) : (
                                <>
                                    <p className="font-bold mb-3 golden-txt text-xl">
                                        Total Eventss : <span>{data?.total_data_count || 0}</span>
                                    </p>
                                    <div className="grid grid-cols-1 md:grid-cols-12 gap-4 overflow-y-scroll max-h-[1000px] md:pr-2 pb-4">
                                        {data?.data?.length !== 0
                                            ? data?.data?.map((v, i) => {
                                                  return (
                                                      <div key={i} className="col-span-12">
                                                          <EventCard data={v} />
                                                      </div>
                                                  )
                                              })
                                            : null}
                                    </div>
                                </>
                            )}
                            {data?.data?.length === 0 && !loading && (
                                <div className="flex justify-center items-center bg-white rounded-md border-2 border-slate-200 py-8">
                                    <h3 className="text-gray-400 text-center font-medium">
                                        <FontAwesomeIcon icon={faBan} /> No events found
                                    </h3>
                                </div>
                            )}
                            {data.total_data_count > 100 ? (
                                <Pagination
                                    prevPageCount={data.prev_page_count}
                                    currentPageProp={data.current_page}
                                    nextPageCount={data.next_page_count}
                                    setPage={setPage}
                                />
                            ) : null}
                        </div>
                    </div>

                    {/* Right Sidebar */}
                    <div className="w-4/12 p-4">
                        <div className="flex flex-col gap-6 border rounded shadow-sm mb-4 bg-gradient-to-r from-indigo-50 to-indigo-200">
                            {/* <div className="flex justify-between items-center">
                                <div>
                                    <div className="font-semibold">Wed, 03 - Fri, 05 Jul 2024</div>
                                    <div className="text-xl text-primary-green-1 font-bold hover:text-red-600 cursor-pointer">
                                        Aluminum China Expo
                                    </div>
                                    <div className="flex gap-1 text-gray-500">
                                        <LocationOnIcon />
                                        <p className="font-semibold">Shanghai, China</p>
                                    </div>
                                    <div className="">Industrial Engineering</div>
                                </div>
                                <div className="bg-primary-green-1 h-[80px] w-[80px] rounded-md flex justify-center items-center">
                                    <img src="/assets/logo/dent-logo-white.png" height={80} width={80} alt="" />
                                </div>
                            </div>
                            <div className="flex gap-1 text-gray-500">
                                <GroupsIcon />
                                <p className="font-semibold">2558 Members</p>
                            </div> */}
                            <div>
                                <img src={SponsorsBanner} height={300} alt="" />
                            </div>
                        </div>

                        <div className="border rounded shadow-sm mb-4 bg-[#1E201E]">
                            {/* <div className="border p-4 rounded shadow-sm mb-4 bg-gradient-to-r from-amber-50 to-amber-200"> */}
                            {/* <div className="text-lg font-semibold">Wed, 03 - Fri, 05 Jul 2024</div>
                            <div className="mt-2 text-xl font-bold  hover:text-red-600 cursor-pointer">
                                Copper China
                            </div>
                            <div className="text-gray-500">Shanghai, China</div>
                            <div className="mt-2">Industrial Engineering</div>
                            <div className="mt-2 text-gray-500">2558 Members</div> */}

                            {/* <div className="flex justify-evenly items-center">
                                <div>
                                    <div className="text-lg font-semibold text-white">Insurance Advisor</div>
                                    <div className="mt-2 text-xl font-bold text-white hover:text-red-600 cursor-pointer">
                                        Sepidi Rahmani
                                    </div>
                                </div>
                                <div className="rounded-md flex justify-center items-center">
                                    <img src={SepidiImg} height={80} width={80} alt="" />
                                </div>
                            </div> */}
                            <div>
                                <img src={MahshaCardBanner} height={300} alt="" />
                            </div>
                        </div>

                        <div className="border rounded shadow-sm bg-[#1E201E]">
                            {/* <div className="border p-4 rounded shadow-sm bg-gradient-to-r from-lime-50 to-lime-200"> */}
                            {/* <div className="text-lg font-semibold">Wed, 03 - Fri, 05 Jul 2024</div>
                            <div className="mt-2 text-xl font-bold  hover:text-red-600 cursor-pointer">
                                Copper China
                            </div>
                            <div className="text-gray-500">Shanghai, China</div>
                            <div className="mt-2">Industrial Engineering</div>
                            <div className="mt-2 text-gray-500">2558 Members</div> */}

                            {/* <div className="flex justify-evenly items-center">
                                <div>
                                    <div className="text-lg font-semibold text-white">Investment Advisor</div>
                                    <div className="mt-2 text-xl font-bold text-white hover:text-red-600 cursor-pointer">
                                        Mahsa Farboud
                                    </div>
                                </div>
                                <div className="rounded-md flex justify-center items-center">
                                    <img src={MahsaImg} height={80} width={80} alt="" />
                                </div>
                            </div> */}
                            <div>
                                <img src={SepidehCardBanner} height={300} alt="" />
                            </div>
                        </div>
                    </div>
                </Hidden>
                <Hidden smUp>
                    <div className="w-full p-4 bg-light-main-green">
                        <div className="h-full w-full">
                            {/* Sponsor Content */}
                            {/* <div className="w-full">
                                <div className="flex flex-col gap-6 border p-4 rounded shadow-sm mb-4 bg-gradient-to-r from-indigo-50 to-indigo-200">
                                    <div className="flex justify-between items-center">
                                        <div>
                                            <div className="font-semibold">Wed, 03 - Fri, 05 Jul 2024</div>
                                            <div className="text-xl text-primary-green-1 font-bold hover:text-red-600 cursor-pointer">
                                                Aluminum China Expo
                                            </div>
                                            <div className="flex gap-1 text-gray-500">
                                                <LocationOnIcon />
                                                <p className="font-semibold">Shanghai, China</p>
                                            </div>
                                            <div className="">Industrial Engineering</div>
                                        </div>
                                        <div className="bg-primary-green-1 h-[80px] w-[80px] rounded-md flex justify-center items-center">
                                            <img src="/assets/logo/dent-logo-white.png" height={80} width={80} alt="" />
                                        </div>
                                    </div>
                                    <div className="flex gap-1 text-gray-500">
                                        <GroupsIcon />
                                        <p className="font-semibold">2558 Members</p>
                                    </div>
                                </div>

                                <div className="border p-4 rounded shadow-sm mb-4 bg-gradient-to-r from-amber-50 to-amber-200">
                                    <div className="text-lg font-semibold">Wed, 03 - Fri, 05 Jul 2024</div>
                                    <div className="mt-2 text-xl font-bold  hover:text-red-600 cursor-pointer">
                                        Copper China
                                    </div>
                                    <div className="text-gray-500">Shanghai, China</div>
                                    <div className="mt-2">Industrial Engineering</div>
                                    <div className="mt-2 text-gray-500">2558 Members</div>
                                </div>
                            </div> */}
                            <div className="flex flex-col gap-6 border rounded shadow-sm mb-4 bg-gradient-to-r from-indigo-50 to-indigo-200">
                                <div>
                                    <img src={SponsorsBanner} height={300} alt="" />
                                </div>
                                <div className="border rounded shadow-sm mb-4 bg-[#1E201E]">
                                    <div>
                                        <img src={MahshaCardBanner} height={300} alt="" />
                                    </div>
                                </div>

                                <div className="border rounded shadow-sm bg-[#1E201E]">
                                    <div>
                                        <img src={SepidehCardBanner} height={300} alt="" />
                                    </div>
                                </div>
                            </div>

                            {/* Filter Accordion */}
                            <div className="pt-4">
                                <Accordion
                                    expanded={mobFilterExpanded === 'parent'}
                                    onChange={handleMobParentAccordionChange('parent')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panelParentbh-content"
                                        id="panelParentbh-header">
                                        <Typography className="flex items-center" sx={{ width: '75%', flexShrink: 0 }}>
                                            <FilterListIcon /> Filter by
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div>
                                            <div>
                                                <Button onClick={() => handleFilterResetReset()}>Reset Filters</Button>
                                                <div>
                                                    <Accordion
                                                        expanded={expanded === 'panel3'}
                                                        onChange={handleChange('panel3')}>
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls="panel3bh-content"
                                                            id="panel3bh-header">
                                                            <Typography sx={{ width: '75%', flexShrink: 0 }}>
                                                                Sort By
                                                            </Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                            <div>
                                                                {sortByOptions.map((cat, i) => (
                                                                    <div className="mt-2 flex gap-0" key={i}>
                                                                        <input
                                                                            type="checkbox"
                                                                            checked={sortBy === cat?.value}
                                                                            onChange={() => setSortBy(cat?.value)}
                                                                            className="mr-2"
                                                                            id={'by' + i.toString()}
                                                                        />
                                                                        <label
                                                                            for={'by' + i.toString()}
                                                                            className="cursor-pointer">
                                                                            {cat?.text || ''}
                                                                        </label>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                    <Accordion
                                                        expanded={expanded === 'panel4'}
                                                        onChange={handleChange('panel4')}>
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls="panel4bh-content"
                                                            id="panel4bh-header">
                                                            <Typography sx={{ width: '75%', flexShrink: 0 }}>
                                                                Attend By
                                                            </Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                            <div>
                                                                {attendByOptions.map((cat, i) => (
                                                                    <div className="mt-2 flex gap-0" key={i}>
                                                                        <input
                                                                            type="checkbox"
                                                                            checked={searchBy === cat?.value}
                                                                            onChange={() => setSearchBy(cat?.value)}
                                                                            className="mr-2"
                                                                            id={'by' + i.toString()}
                                                                        />
                                                                        <label
                                                                            for={'by' + i.toString()}
                                                                            className="cursor-pointer">
                                                                            {cat?.text || ''}
                                                                        </label>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                    <Accordion
                                                        expanded={expanded === 'panel0'}
                                                        onChange={handleChange('panel0')}>
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls="panel0bh-content"
                                                            id="panel0bh-header">
                                                            <Typography sx={{ width: '75%', flexShrink: 0 }}>
                                                                Date
                                                            </Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                            <div>
                                                                {/* <div className="mt-2 flex gap-0">
                                                <input
                                                    type="checkbox"
                                                    checked={upcoming}
                                                    onChange={() => setUpcoming(true)}
                                                    className="mr-2"
                                                    id="up"
                                                />
                                                <label for={'up'} className="cursor-pointer">
                                                    Upcomming
                                                </label>
                                            </div>
                                            <div className="mt-2 flex gap-0">
                                                <input
                                                    type="checkbox"
                                                    checked={!upcoming}
                                                    onChange={() => setUpcoming(false)}
                                                    className="mr-2"
                                                    id="prev"
                                                />
                                                <label for={'prev'} className="cursor-pointer">
                                                    Previous
                                                </label>
                                            </div> */}
                                                                {eventsByOptions.map((cat, i) => (
                                                                    <>
                                                                        <div className="mt-2 flex gap-0" key={i}>
                                                                            {/* <input
                                                        type="checkbox"
                                                        checked={ceSpeciality === cat?.value}
                                                        onChange={() => setCeSpeciality(cat?.value)}
                                                        className="mr-2"
                                                    /> */}
                                                                            <input
                                                                                type="checkbox"
                                                                                checked={eventsBy === cat?.value}
                                                                                onChange={() =>
                                                                                    handleEventsByChange(cat?.value)
                                                                                }
                                                                                className="mr-2"
                                                                                id={'spec' + i.toString()}
                                                                            />
                                                                            <label
                                                                                for={'spec' + i.toString()}
                                                                                className="cursor-pointer">
                                                                                {cat?.text || ''}
                                                                            </label>
                                                                        </div>
                                                                    </>
                                                                ))}
                                                                {eventsBy === 'custom' && (
                                                                    <>
                                                                        <div className="col-span-12 md:col-span-6">
                                                                            <Input
                                                                                type="date"
                                                                                name="event-start-date"
                                                                                label="Start date"
                                                                                labelClass="text-md font-semibold"
                                                                                inputClass="block w-full border-2 border-gray-300 p-2 mt-2 mb-6 focus:outline-0 rounded-md "
                                                                                placeHolder=""
                                                                                value={startDate}
                                                                                setValue={setStartDate}
                                                                                required={true}
                                                                            />
                                                                        </div>
                                                                        <div className="col-span-12 md:col-span-6">
                                                                            <Input
                                                                                type="date"
                                                                                name="event-end-date"
                                                                                label="End date"
                                                                                labelClass="text-md font-semibold"
                                                                                inputClass="block w-full border-2 border-gray-300 p-2 mt-2 mb-6 focus:outline-0 rounded-md "
                                                                                placeHolder=""
                                                                                value={endDate}
                                                                                setValue={setEndDate}
                                                                                required={true}
                                                                            />
                                                                        </div>
                                                                    </>
                                                                )}
                                                            </div>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                    <Accordion
                                                        expanded={expanded === 'panel1'}
                                                        onChange={handleChange('panel1')}>
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls="panel1bh-content"
                                                            id="panel1bh-header">
                                                            <Typography sx={{ width: '75%', flexShrink: 0 }}>
                                                                Events Type
                                                            </Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                            <div>
                                                                {[{ key: 'ALL', value: { value: 'all' } }]
                                                                    .concat(EventCategoryArray)
                                                                    .map((cat, i) => (
                                                                        <div className="mt-2 flex gap-0" key={i}>
                                                                            <input
                                                                                type="checkbox"
                                                                                checked={category === cat?.value?.value}
                                                                                onChange={() =>
                                                                                    setCategory(cat?.value?.value)
                                                                                }
                                                                                className="mr-2"
                                                                                id={'cat' + i.toString()}
                                                                            />
                                                                            <label
                                                                                for={'cat' + i.toString()}
                                                                                className="cursor-pointer">
                                                                                {cat?.key || ''}
                                                                            </label>
                                                                        </div>
                                                                    ))}
                                                            </div>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                    <Accordion
                                                        expanded={expanded === 'panel2'}
                                                        onChange={handleChange('panel2')}>
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls="panel2bh-content"
                                                            id="panel2bh-header">
                                                            <Typography sx={{ width: '75%', flexShrink: 0 }}>
                                                                CE Speciality
                                                            </Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                            <div>
                                                                {[{ text: 'All', value: 'all' }]
                                                                    .concat(dentalCategoryOptions)
                                                                    .map((cat, i) => (
                                                                        <div className="mt-2 flex gap-0" key={i}>
                                                                            {/* <input
                                                        type="checkbox"
                                                        checked={ceSpeciality === cat?.value}
                                                        onChange={() => setCeSpeciality(cat?.value)}
                                                        className="mr-2"
                                                    /> */}
                                                                            <input
                                                                                type="checkbox"
                                                                                checked={
                                                                                    ceSpecialities.includes(
                                                                                        cat?.value,
                                                                                    ) || ceSpecialities.includes('all')
                                                                                }
                                                                                onChange={() =>
                                                                                    handleCeSpecilities(cat?.value)
                                                                                }
                                                                                className="mr-2"
                                                                                id={'spec' + i.toString()}
                                                                            />
                                                                            <label
                                                                                for={'spec' + i.toString()}
                                                                                className="cursor-pointer">
                                                                                {cat?.text || ''}
                                                                            </label>
                                                                        </div>
                                                                    ))}
                                                            </div>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                    <Accordion
                                                        expanded={expanded === 'panel5'}
                                                        onChange={handleChange('panel5')}>
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls="panel5bh-content"
                                                            id="panel5bh-header">
                                                            <Typography sx={{ width: '75%', flexShrink: 0 }}>
                                                                Price
                                                            </Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                            <div>
                                                                <Box sx={{ width: '100%' }}>
                                                                    <Slider
                                                                        getAriaLabel={() => 'Price range'}
                                                                        value={priceValue}
                                                                        onChange={priceHandleChange}
                                                                        valueLabelDisplay="auto"
                                                                        getAriaValueText={valuetext}
                                                                        min={0}
                                                                        max={1200}
                                                                    />
                                                                </Box>
                                                            </div>
                                                        </AccordionDetails>
                                                    </Accordion>

                                                    <Accordion
                                                        expanded={expanded === 'panel6'}
                                                        onChange={handleChange('panel6')}>
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls="panel6bh-content"
                                                            id="panel6bh-header">
                                                            <Typography sx={{ width: '75%', flexShrink: 0 }}>
                                                                Country/State/City
                                                            </Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                            <div>
                                                                <h6>Country</h6>
                                                                <CountrySelect
                                                                    // defaultValue={{
                                                                    //     emoji: countryEmoji,
                                                                    //     id: countryid,
                                                                    //     name: country,
                                                                    // }}
                                                                    onChange={(e) => {
                                                                        setCountryEmoji(e.emoji)
                                                                        setCountryid(e.id)
                                                                        setCountry(e?.name || '')
                                                                    }}
                                                                    placeHolder="Select Country"
                                                                />
                                                                <h6>State</h6>
                                                                <StateSelect
                                                                    countryid={countryid}
                                                                    onChange={(e) => {
                                                                        setstateid(e.id)
                                                                        setState(e?.name || '')
                                                                    }}
                                                                    placeHolder="Select State"
                                                                />
                                                                <h6>City</h6>
                                                                <CitySelect
                                                                    countryid={countryid}
                                                                    stateid={stateid}
                                                                    onChange={(e) => {
                                                                        setCity(e?.name || '')
                                                                    }}
                                                                    placeHolder="Select City"
                                                                />
                                                            </div>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                </div>
                                            </div>
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                            </div>

                            {/* Events */}
                            <div className="w-full mb-4">
                                <div className="pt-4">
                                    {/* {!search && (
                                        <EventCards
                                            title={upcoming ? 'Upcoming events' : 'Previous events'}
                                            by="event_name"
                                            keyword=""
                                            upcoming={upcoming}
                                            featured={null}
                                            limitPerPage={20}
                                            isMiddleSection={true}
                                        />
                                    )} */}
                                    {loading ? (
                                        <div className="flex justify-center items-center min-h-[200px]">
                                            <FontAwesomeIcon
                                                icon={faSpinner}
                                                className="motion-safe:animate-spin text-4xl text-primary-green-2"
                                            />
                                        </div>
                                    ) : (
                                        <>
                                            <p className="font-bold mb-3 golden-txt text-xl">
                                                Total Events : <span>{data?.total_data_count || 0}</span>
                                            </p>
                                            <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
                                                {data?.data?.length !== 0
                                                    ? data?.data?.map((v, i) => {
                                                          return (
                                                              <div key={i} className="col-span-12">
                                                                  <EventCard data={v} />
                                                              </div>
                                                          )
                                                      })
                                                    : null}
                                            </div>
                                        </>
                                    )}
                                    {data?.data?.length === 0 && !loading && (
                                        <div className="flex justify-center items-center bg-white rounded-md border-2 border-slate-200 py-8">
                                            <h3 className="text-gray-400 text-center font-medium">
                                                <FontAwesomeIcon icon={faBan} /> No events found
                                            </h3>
                                        </div>
                                    )}
                                    {data.total_data_count > 20 ? (
                                        <Pagination
                                            prevPageCount={data.prev_page_count}
                                            currentPageProp={data.current_page}
                                            nextPageCount={data.next_page_count}
                                            setPage={setPage}
                                        />
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </Hidden>
            </div>
        </div>
    )
}
