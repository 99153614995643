import { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { settings, LocalVar } from '../../../utils'
import { Input, Button } from '../../atoms'
import { Link, useLocation, useNavigate } from 'react-router-dom'
// import SigInGoogle from '../../../assets/icon/sign-in-google.png'
// import SigInApple from '../../../assets/icon/sign-in-apple-id.png'
import { Modal } from '@components/atoms/index'
import axios from 'axios'
import { resendVerifyEmailAPI } from '@api/index'
import { Google0AuthLogin } from '..'

const LoginForm = () => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [loggedIn, setLoggedIn] = useState(false)
    const [loading, setLoading] = useState(false)
    const [sendLoad, setSendLoad] = useState(false)
    const [openModal, setOpenModal] = useState(false)
    const location = useLocation()
    const { state } = location

    const navigate = useNavigate()

    useEffect(() => {
        // work here
        if (loggedIn === true) {
            if (location.pathname === '/login') {
                navigate('/events')
            } else {
                navigate(location.pathname || '/events')
            }
            // window.location.reload()
        }
    }, [loggedIn, navigate, location])

    const submit = (e) => {
        e.preventDefault()

        if (loading === true) return
        setLoading(true)

        let body = {
            identifier: email ? email : state?.data?.email ? state?.data?.email : email,
            secret: password ? password : state?.data?.password ? state?.data?.password : password,
        }

        const fetchAPI = axios.create({ baseURL: settings.api_url_v1() })
        fetchAPI
            .post('/sessions/', { ...body })
            .then((data) => {
                localStorage.setItem(LocalVar.AUTH_DATA, JSON.stringify(data.data))
                toast.success('Welcome to DentiCE')
                setLoading(false)
                setLoggedIn(true)
                window.location.reload()
            })
            .catch((err) => {
                setLoading(false)
                const pop = err?.response?.data?.errors
                    ? err?.response?.data?.errors[0]?.message
                    : 'Something went wrong'
                toast.error(pop)
            })
    }

    const resendSubmit = async (e) => {
        e.preventDefault()
        if (email.length === 0) return
        setSendLoad(true)
        const vr = await resendVerifyEmailAPI({ email: email })
        if (vr.error === null) {
            setSendLoad(false)
            toast.success('Email send!')
            navigate(`/verify/${email}`)
        } else {
            setSendLoad(false)
            toast.error(vr.error.response.data.errors[0].message)
        }
    }
    return (
        <div className="h-full w-full flex justify-center overflow-auto">
            <div className="w-[400px] m-auto py-8">
                <h2 className="font-w6">Welcome Back!</h2>
                <p className="text-gray-700 py-4">Get your data-driven insights</p>
                <form onSubmit={submit}>
                    <Input
                        name="email"
                        label="Email"
                        type="email"
                        value={state?.data?.email ? state?.data?.email : ''}
                        setValue={setEmail}
                        placeHolder="Your Email"
                        required={true}
                    />
                    <Input
                        name="password"
                        label="Password"
                        labelClass="text-md font-semibold"
                        type="password"
                        wrapperClass="relative"
                        eyeIconClass="cursor-pointer absolute right-4 top-10"
                        value={state?.data?.password ? state?.data?.password : ''}
                        setValue={setPassword}
                        inputClass="block w-full border-2 border-gray-300 p-2 my-2 focus:outline-0 rounded-md bg-gray-100"
                        placeHolder="Your Password"
                        required={true}
                    />
                    <div className="relative py-4">
                        {/* <p
                            onClick={() => setOpenModal(true)}
                            className="text-sm font-medium text-primary-green-1 absolute left-0 cursor-pointer">
                            Verify your email
                        </p> */}
                        <Link
                            to="/forgot-password"
                            className="text-sm font-medium text-primary-green-1 absolute right-0">
                            Forgot Password?
                        </Link>
                    </div>
                    <Button loading={loading}>Sign In</Button>
                    <div className="mt-6">{/* <Google0AuthLogin /> */}</div>
                </form>
                {/* <button className="font-semibold block w-full text-center p-4 border-2 border-gray-300 rounded-md mt-6 ">
                    <img className="w-6 inline" src={SigInGoogle} alt="Google-Sign-In" /> Sign In with google
                </button>
                <button className="font-semibold block w-full text-center p-4 border-2 border-gray-300 rounded-md mt-6">
                    <img className="w-6 inline" src={SigInApple} alt="Apple-Sign-In" /> Sign In with apple
                </button> */}

                <p className="font-semibold text-gray-400 text-center my-8">OR</p>
                <p className="font-semibold text-gray-400 text-center mt-8">
                    Don't have an account?{' '}
                    <Link to="/signup" className="text-primary-green-1">
                        Sign up
                    </Link>
                </p>
            </div>
            {openModal && (
                <Modal title="Verify your email" setValue={setOpenModal}>
                    <div className="min-w-[300px]">
                        <form onSubmit={resendSubmit}>
                            <Input
                                name="email"
                                label="Email"
                                type="email"
                                setValue={setEmail}
                                placeHolder="Your Email"
                            />
                            <Button loading={sendLoad}>Send</Button>
                        </form>
                    </div>
                </Modal>
            )}
        </div>
    )
}
export default LoginForm
