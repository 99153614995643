import { useReducer, useState, useEffect } from 'react'
import { Routes, Route } from 'react-router-dom'
import {
    LoginPage,
    SignupPage,
    SignupAdminPage,
    HomePage,
    ProfilePage,
    EventFinderPage,
    NotFound,
    MainPage,
    SetRole,
    ForgotPasswordPage,
    ResetPasswordPage,
    EventAddPage,
    EventDetailsPage,
    FavoriteEventsPage,
    SettingsPage,
    VerifyPage,
    SelectRolePage,
    VirtualCEPage,
    MentorshipPage,
    DentalDealsPage,
    EventEditPage,
    UsersListTable,
    UserActivity,
} from '@components/pages/index'
import { Loading } from '@components/atoms/index'
import { Toaster } from 'react-hot-toast'
import { ProtectedRoutes, UnshelteredRoute, GetAuthDataRoute } from './utils'
import { Auth } from './context'
import { initialAuth, authtReducer } from './reducer'
import { HelmetProvider } from 'react-helmet-async'
import AllEvents from '@components/pages/AllEvents'
import SearchedPage from '@components/pages/SearchedPage'
import PrivacyPolicyPage from '@components/pages/PrivacyPolicyPage'
import TopRatedPage from '@components/pages/TopRatedPage'

const App = () => {
    const [state, dispatch] = useReducer(authtReducer, initialAuth)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        const handleBeforeUnload = () => {
            setIsLoading(true)
        }

        const handleAfterLoad = () => {
            setIsLoading(false)
        }

        window.addEventListener('beforeunload', handleBeforeUnload)
        window.addEventListener('load', handleAfterLoad)

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload)
            window.removeEventListener('load', handleAfterLoad)
        }
    }, [])

    return (
        <div>
            {isLoading ? <Loading /> : null}
            <Auth.Provider value={{ state, dispatch }}>
                <HelmetProvider>
                    <Routes>
                        <Route element={<GetAuthDataRoute />}>
                            <Route path="/" element={<MainPage />} />
                            <Route path="/events" element={<EventFinderPage />} />
                            <Route path="/virtualce" element={<VirtualCEPage />} />
                            <Route path="/mentorship" element={<MentorshipPage />} />
                            <Route path="/dentaldeals" element={<DentalDealsPage />} />
                            <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
                            <Route path="/top-rated/:type" element={<TopRatedPage />} />
                        </Route>
                        <Route element={<UnshelteredRoute />}>
                            <Route path="/login" element={<LoginPage />} />
                            <Route path="/signup" element={<SignupPage />} />
                            <Route path="/admin" element={<SignupAdminPage />} />
                            <Route path="/forgot-password" element={<ForgotPasswordPage />} />
                            <Route path="/reset-password" element={<ResetPasswordPage />} />
                            <Route path="/verify/:email" element={<VerifyPage />} />
                        </Route>

                        <Route element={<ProtectedRoutes />}>
                            <Route path="/home" element={<HomePage />} />
                            <Route path="/profile" element={<ProfilePage />} />
                            <Route path="/set-role" element={<SetRole />} />
                            <Route path="/switch-profile" element={<SelectRolePage />} />
                            <Route path="/settings" element={<SettingsPage />} />
                            <Route path="/event-add" element={<EventAddPage />} />
                            <Route path="/event-edit/:eventId" element={<EventEditPage />} />
                            <Route path="/events/:eventId" element={<EventDetailsPage />} />
                            <Route path="/personalized-events" element={<FavoriteEventsPage />} />
                            <Route path="/analytics" element={<UsersListTable />} />
                            <Route path="/analytics/:accountId" element={<UserActivity />} />
                            <Route path="/all-events/:eventsType" element={<AllEvents />} />
                            <Route path="/searched-page" element={<SearchedPage />} />
                            <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
                        </Route>
                        <Route path="*" element={<NotFound />} />
                        {/* <Route
                            path="/no-access"
                            element={<NotFound statusCode={''} warningTxt={'Please login to access this page!'} />}
                        /> */}
                    </Routes>
                    <Toaster position="top-center" reverseOrder={false} />
                </HelmetProvider>
            </Auth.Provider>
        </div>
    )
}
export default App
